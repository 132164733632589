import React, { useState } from "react";
import { ProjectDetail } from "../util/ReadDb";
import TagList from "./TagComponent";
import styles from './styles.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const SummaryDescription = (props: {
  project: ProjectDetail,
  descriptionVisibility: boolean
}) => {
  const {project, descriptionVisibility} = props;

  if (descriptionVisibility) {
    return (
      <div className={styles.clickable}>
        <p>{project.description} {"\t"}
          <FontAwesomeIcon icon="angle-up" className={styles.colorRed}/>
        </p>
      </div>
    )
  } else {
    return (
      <div className={styles.clickable}>
        <p>{project.summary} {"\t"}
          <FontAwesomeIcon icon="angle-down" className={styles.colorRed}/>
        </p>
      </div>
    )
  }
}


function FocusedProjectItem (props: {
  project: ProjectDetail, 
  index: number
}) {
  const { project, index } = props;

  const [descriptionVisibility, setDescriptionVisibility] = useState(false);

  const handleToggle = () => {
    setDescriptionVisibility(!descriptionVisibility)
  }

  return (
    <div key={index} className={[styles.projectItem, styles.focused].join(' ')}>
      <div className={styles.header}>
        <h2>{project.role}</h2>
        <h4>{project.title}</h4>
      </div>
      <div onClick={ handleToggle }>
        <SummaryDescription project={project} descriptionVisibility={descriptionVisibility} />
      </div>
      <p>{project.startDate} - {project.endDate ? project.endDate: "ongoing"}</p>
      <div className={styles.tagWrapper}>
        <p className={styles.tagHeader}>TOPICS </p>
        {TagList(project.topics, "topics")}
      </div>
      {project.tech.length > 0 ? 
        <div className={styles.tagWrapper}>
          <p className={styles.tagHeader}>TECH </p>
          {TagList(project.tech, "tech")}
        </div>
      : null }
      <div className={styles.linkPlacer}>
        {project.website.length > 1 ? <a href={`${project.website}`} target="blank_">
          <FontAwesomeIcon icon="external-link-alt" className={styles.icon}/>
        </a> : null }
      </div>
    </div>
  )
}

const UnfocusedProjectItem = (props: {
  project: ProjectDetail, 
  index: number
}) => {
  const { project, index } = props;
  return (
    <div key={index} className={[styles.projectItem, styles.unfocused].join(' ')}>
      <div className={styles.header}>
        <h2>{project.role}</h2>
        <h4>{project.title}</h4>
      </div>
      <div>
        <p>{project.summary}</p>
      </div> 
    </div>
  )
}


function ProjectItem (props: {
  project: ProjectDetail, 
  index: number, 
  viewState: string
  }) {
  const { project, index, viewState } = props

  if (project.meta.areas.some(item => item === viewState) 
      || project.meta.type === viewState) {
  return ( <FocusedProjectItem project={project} index={index} /> );
  } else {
  return ( <UnfocusedProjectItem project={project} index={index} /> );
  }  
};

enum ViewEnum {
  SUSTAINABILITY = "SUSTAINABILITY",
  ENTREPRENEURSHIP = "ENTREPRENEURSHIP",
  ENGINEERING = "ENGINEERING",
  EXPERIENCE = "EXPERIENCE",
  EDUCATION = "EDUCATION",
  OTHER = "OTHER",
}


function ProjectComponent (props : { 
  projects: ProjectDetail[] 
}) {
  const { projects } = props

  const [viewState, setViewState] = useState(ViewEnum.ENGINEERING)
  const [showNewestFirst, setShowNewestFirst] = useState(true)

  const startDateComparator = (projectA: ProjectDetail, projectB: ProjectDetail) => {

    let dateA = new Date(projectA.startDate)
    let dateB = new Date(projectB.startDate)

    if (dateA < dateB) {
      return -1;
    } else if (dateA > dateB) {
      return 1;
    } else {
      return 0;
    }
  }

  // let projectsToShow = projects.filter(project => project.meta.areas.includes(viewState))
  // let projectsToShow = projects
  let projectsToShow = projects.sort( startDateComparator )

  if (showNewestFirst) projectsToShow.reverse();

  const SelectorButton = (props: {
    title: string,
    value: any,
    compareValue: any,
    onClick: any,
  }) => {
    const {title, value, compareValue, onClick} = props;

    let color: string;
    if (compareValue === value) {
      color = styles.colorActive
    } else {
      color = styles.colorInactive
    }

    return (
      <div onClick={onClick} className={[styles.selectorButton, color].join(' ')} >
        <p className={styles.selectorButtonText}>{title}</p>
      </div>
    )
  }

  return (
    <div>
      <div className={styles.selectorWrapper}>
        <SelectorButton 
          title={"Engineering & Development"}
          value={ViewEnum.ENGINEERING}
          compareValue={viewState}
          onClick={() => setViewState(ViewEnum.ENGINEERING)} 
        />
        <SelectorButton 
          title={"Sustainability"}
          value={ViewEnum.SUSTAINABILITY}
          compareValue={viewState}
          onClick={() => setViewState(ViewEnum.SUSTAINABILITY)} 
        />
        <SelectorButton 
          title={"Entrepreneurship"}
          value={ViewEnum.ENTREPRENEURSHIP}
          compareValue={viewState}
          onClick={() => setViewState(ViewEnum.ENTREPRENEURSHIP)} 
        />
        <SelectorButton 
          title={"Work Experience"}
          value={ViewEnum.EXPERIENCE}
          compareValue={viewState}
          onClick={() => setViewState(ViewEnum.EXPERIENCE)} 
        />
        <SelectorButton 
          title={"Education"}
          value={ViewEnum.EDUCATION}
          compareValue={viewState}
          onClick={() => setViewState(ViewEnum.EDUCATION)} 
        />
        <SelectorButton 
          title={"Extracuricular"}
          value={ViewEnum.OTHER}
          compareValue={viewState}
          onClick={() => setViewState(ViewEnum.OTHER)} 
        />
        <SelectorButton 
          title={"Show newest first"}
          value={true}
          compareValue={showNewestFirst}
          onClick={() => setShowNewestFirst(!showNewestFirst)} 
        />
      </div>


      <div className={styles.projectWrapper}>
        {projectsToShow.map((item, idx) => <ProjectItem project={item} index={idx} viewState={viewState} />)}
      </div>
    </div>
  )
}

export default ProjectComponent;