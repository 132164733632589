import styles from './styles.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'


type linkType = {
  name: string;
  link: string;
  icon: IconProp
}


const links: linkType[] = [
  {
    name: "Github",
    link: "https://github.com/nils-earth/",
    icon: ["fab", "github"]
  },{
    name: "Impact Design",
    link: "https://www.impactdesign.se/cube",
    icon: ["fas", "cube"]
  },{
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/nils-weber/",
    icon: ["fab", "linkedin"]
  },
]



const LinkItem = (item: linkType, idx: number) => {
  return (
    <div key={idx} >
      <a href={item.link} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={item.icon} className={styles.icon}/>
      </a>
    </div>
  );
}

const HeaderComponent = () => {
  return (
    <div className={styles.header}>
      <h1>Nils Weber</h1>
      <p>I studied engineering, then I started building companies and discovered sustainability as a worthwhile challenge. Most of the things I do combine these three areas in some form. Click around below to see how.</p>
      <div className={styles.iconWrapper}>
      {
        links.map((link, idx) => LinkItem(link, idx))
      }
      </div>
    </div>
  );
}


export default HeaderComponent;
