import data from '../db.json'

export type ProjectDetail = {
  title: string;
  meta: {
    type: string;
    areas: string[];
  }
  role: string;
  summary: string,
  description: string,
  startDate: string;
  endDate?: string;
  website: string;
  topics: string[];
  tech: string[];
}

export const getDbAsObject = () => {
  return data as ProjectDetail[]
}
