import styles from './styles.module.css'

const TagItem = (title: string, idx: number, tagType: string) => {

  let color: any;
  switch (tagType) {
    case "topics":
      color = styles.colorRed
      break;
    case "tech":
      color = styles.colorBlue
      break;
  }

  const topic = (
    <div key={idx} className={[styles.tag, color].join(' ')}>
      <p>{title}</p>
    </div>
  )
  return topic;
}

const TagList = (topics: string[], tagType: string) => {
  return topics.map((topic, idx) => TagItem(topic, idx, tagType))
}


export default TagList;