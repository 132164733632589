import React from 'react';
import './App.css';
import HeaderComponent from './components/HeaderComponent';
import ProjectComponent from './components/ProjectComponent';
import { getDbAsObject } from './util/ReadDb';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faExternalLinkAlt, faCube, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faCheckSquare, faCoffee, faExternalLinkAlt, faCube, faAngleDown, faAngleUp)

/**
 * IDEA
 * Since i can present my skills and experiences in different ways, use react for that! 
 * The viewer could switch between
 *  - chronological
 *  - fields (entrepreneur, sustainability, engineering)
 *  - job vs project
 * 
 * A JSON file, maybe later a DB would hold the items with different categories that 
 * could be used to sort the items into the different systems.
 * 
 */

function App() {
  let data = getDbAsObject()
  return (
    <div className="App">
      <HeaderComponent />
      <ProjectComponent projects={data} />
    </div>
  );  
}

export default App;
